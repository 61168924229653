import request from '@/utils/request'
import DefaultModel from '@/models/productos'
import { format, differenceInDays } from 'date-fns'

const state = {
    lista: [],
    datos: new DefaultModel.BaseForm(),
    listaMarca: []
}

const getters = {
    lista: (_state) => (_state.lista.length > 0 ? _state.lista : [])
}

const mutations = {
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    SET_LISTA_MARCA: (state, payload) => {
        state.listaMarca = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerLista({ state, commit }, payload) {
        console.log('payload ', payload)
        if (state.lista.length < 1) {
            //const fromDate = payload.fechaFiltro ? format(payload.fechaFiltro, 'YYYY-MM-DD') : null
            const { data } = await request({
                url: '/api/private/productos',
                method: 'GET',
                params: {
                    //      fromDate,
                }
            })

            console.log('obtenerLista productos', data)
            commit('SET_LISTA', data.payload)
        } else {
            console.log('PRODUCTOS_FROM_CACHE')
        }
    },
    async registrar({ commit }, payload) {
        payload.importe = parseFloat(payload.importe)
        console.log('post_payload ', payload)
        const { data } = await request({
            url: '/api/private/productos/',
            method: 'POST',
            data: payload
        })
        console.log('data', data)
    },
    async getMarcas({ state, commit }, idTipoMarca) {
        console.log('idTipoMarca ', idTipoMarca)
        if (state.listaMarca.length < 1) {
            const { data } = await request({
                url: `/api/private/productos/marcas`,
                method: 'GET'
            })
            console.log('data', data)
            commit('SET_LISTA_MARCA', data.payload)
            return data
        }
    },

    async actualizar({ commit, rootState }, payload) {
        const datos = rootState.usuarios.userLogin
        payload.usuarioModificacion = datos.usuario
        payload.importe = parseFloat(payload.importe)

        const { data } = await request({
            url: `/api/private/productos/${payload.id}`,
            method: 'PUT',
            data: payload
        })
        console.log('data', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
