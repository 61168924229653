import request from '@/utils/request'
import { sortItems, buildFilterPayload } from '@/utils/genericUtils'
import DefaultModel from '@/models/prospectos'
import { format } from 'date-fns'

const state = {
    lista: [],
    listaEstadoProspectos: [],
    datos: new DefaultModel.BaseForm()
}

const getters = {
    lista: (_state) => (_state.lista ? _state.lista : []),
    listaEstadoProspectos: (_state) => {
        const items = _state.listaEstadoProspectos
        if (items) {
            return items.sort((a, b) => b.id - a.id)
        } else {
            return []
        }
    }
}

const mutations = {




}

const actions = {
    async reporteModelo({ commit, rootState }, payload) {
        console.log('post_payload ', payload)
        const { data } = await request({
            url: '/api/private/prospectos/reporte/top/modelos',
            method: 'GET',
            params: {
                fromDate: format(payload.fechaFiltro, 'YYYY-MM-DD')
            }
        })

        console.log('obtenerLista', data)
        return data.payload
    },
    async reporteOrigenProspecto({ commit, rootState }, payload) {
        const datos = rootState.usuarios.userLogin
        const usuarioOrigen = datos.usuario

        //
        const { data } = await request({
            url: '/api/private/reportes/origen/prospecto',
            method: 'POST',
            data: buildFilterPayload(payload, usuarioOrigen)
        })

        console.log('obtenerLista', data)
        return data.payload
    },
    async reporteOrigenes({ commit, rootState }, payload) {
        console.log('post_payload ', payload)
        const { data } = await request({
            url: '/api/private/prospectos/reporte/top/origenes',
            method: 'GET',
            params: {
                fromDate: format(payload.fechaFiltro, 'YYYY-MM-DD')
            }
        })

        console.log('obtenerLista', data)

        return data.payload
    },
    async reporteEstados({ commit, rootState }, payload) {
        console.log('post_payload ', payload)
        const { data } = await request({
            url: '/api/private/prospectos/reporte/top/estados',
            method: 'GET',
            params: {
                fromDate: format(payload.fechaFiltro, 'YYYY-MM-DD')
            }
        })

        console.log('obtenerLista', data)

        return data.payload
    },
    async reporteOrigenEstadoProspecto({ commit, rootState }, payload) {
        const datos = rootState.usuarios.userLogin
        const usuarioOrigen = datos.usuario

        const { data } = await request({
            url: '/api/private/reportes/origen/estado',
            method: 'POST',
            data: buildFilterPayload(payload, usuarioOrigen)
        })
        console.log('iterator ', data)
        let newItems = []
        if (data.payload && data.payload['listEstadoOrigen']) {
            const ArrayList = ['idEstado', 'estado', 'cantidad']

            for (const iterator of data.payload.listEstadoOrigen[0].data) {
                ArrayList.push(iterator.origen)
            }
            var rObj = {}

            let origenEstado = []
            data.payload.resEstados.map((x) => {
                const isFindEstadoOrigen = data.payload.listEstadoOrigen.filter((y) => y.idEstado == x.idEstado).map((s) => s.data)
                for (const iterator2 of isFindEstadoOrigen) {
                    for (const iterator3 of iterator2) {
                        rObj[iterator3.origen] = iterator3.cantidad
                    }
                }
                origenEstado.push({ ...x, ...rObj })
            })
            return { data: origenEstado, header: ArrayList }

            // let newItemsLeft = []
            // data.payload['listEstadoOrigen'].map((x) => {
            //     let newData = []
            //     let body = {
            //         idEstado: x.idEstado
            //     }
            //     x.data.map((y) => {
            //         body[`${y.origen}`] = y.cantidad
            //     })
            //     newItemsLeft.push(body)
            // })
            // // new add other array
            // data.payload['resEstados'].map((x) => {
            //     const listFind = newItemsLeft.find((y) => y.idEstado == x.idEstado)
            //     newItems.push({
            //         idEstado: x.idEstado,
            //         estado: x.estado,
            //         cantidad: x.cantidad,
            //         ...listFind
            //     })
            // })
            // console.log('newItems ------------', newItems)
            // return newItems
        }
    },
    async reportePanelProspectos({ commit, rootState, rootGetters }, payload) {
        const usu = rootState.usuarios.userLogin
        const listaEstado = rootGetters['estados/lista']
        console.log(' __ listaEstado __ ', listaEstado)
        const usuarioOrigen = usu.usuario

        const { data } = await request({
            url: '/api/private/reportes/panel/prospectos',
            method: 'POST',
            data: buildFilterPayload(payload, usuarioOrigen)
        })

        let newItems = []
        if (data.payload && data.payload['listPro']) {
            data.payload.listPro.map((x) => {
                let nameEstado = []
                const isFindEstado = data.payload.listEstProspe.filter((y) => y.idProspecto == x.idProspecto)
                listaEstado.map((y) => {
                    const isFP = isFindEstado.filter((z) => z.idEstado == y.id)
                    nameEstado.push({
                        orden: y.orden,
                        descripcion: y.descripcion,
                        isFind: isFP.length > 0 ? 'si' : '-',
                        fechaMovimiento: isFP.length > 0 ? isFP[0].fechaMovimiento : null
                    })
                })
                let myObject = {
                    idProspecto: x.idProspecto,
                    fechaCreacion: x.fechaCreacion,
                    horaCreacion: x.horaCreacion,
                    usuarioCreacion: x.usuarioCreacion,
                    usuarioModificacion: x.usuarioModificacion,
                    descripcion: x.descripcion,
                    origen: x.origen,
                    canal: x.canal,
                    modelo: x.modelo,
                    modeloOrigen: x.modeloOrigen,
                    marcaOrigen: x.marcaOrigen,
                    departamento: x.departamento,
                    provincia: x.provincia,
                    distrito: x.distrito,
                    direccionCompletaEnvio: x.direccionCompletaEnvio,
                    tipoDocumento: x.tipoDocumento,
                    numeroDocumento: x.numeroDocumento,
                    nombreCompletos: x.nombreCompletos,
                    telefono: x.telefono,
                    correo: x.correo,
                    tipoPago: x.tipoPago,
                    metodoEnvio: x.metodoEnvio
                }
                const _nameEstado = sortItems(nameEstado, 'orden', 2)
                _nameEstado.map((y) => {
                    myObject[`${y.descripcion}`] = y.isFind
                    myObject[`${y.descripcion}_Fecha`] = y.fechaMovimiento
                })
                newItems.push(myObject)
            })
        }
        return newItems
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
