import { sortItems } from '@/utils/genericUtils'

const state = {
    tipoDocumento: [
        { text: 'DNI', id: 1 },
        { text: 'RUC', id: 6 },
        { text: 'Pasaporte', id: 2 },
        { text: 'PTP', id: 3 },
        { text: 'Carnet de ext.', id: 4 },
        { text: 'NA', id: 7 }
    ],
    _bussines: [
        {
            id: 1,
            description: 'CAYMAN S AC'
        },
        {
            id: 2,
            description: 'CORPORACIÓN CAYMAN SAC'
        },
        {
            id: 3,
            description: 'ZS MOTORS'
        }
    ],
    tipoMoneda: [
        {
            id: 1,
            text: 'S/.',
            value: 'Soles',
            country: 'es-PE',
            currency: 'PEN'
        },
        {
            id: 2,
            text: '$',
            value: 'Dolares',
            country: 'en-US',
            currency: 'USD'
        }
    ],
    metodoEnvio: [
        {
            id: 4,
            text: 'Domicilio'
        },
        {
            id: 3,
            text: 'Recojo en Tienda'
        }
    ],
    selectCargo: [
        { id: 2, text: 'ADMINISTRADOR' },
        { id: 3, text: 'SUPER_ADMIN' },
        { id: 1, text: 'OTRO' }
    ]
}

const mutations = {}
const actions = {}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
