import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login/Index.vue'

import Xlsx from '@/pages/xlsx/index.vue'

import Layout from '@/layout/index.vue'

function lazyComponent(name) {
    return () => ({
        component: import(`@/pages/${name}.vue`)
    })
}
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: Layout,
            //redirect: 'noRedirect',
            name: 'Prospectos',
            meta: {
                title: 'prospectos',
                icon: 'chart'
            },
            children: [
                {
                    path: '',
                    component: () => import('@/pages/prospectos/index'),
                    name: 'mainProspectos',
                    meta: { title: 'Prospectos', noCache: true }
                }
            ]
        },
        {
            path: '/reportes',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/reportes/index')
                }
            ]
        },
        {
            path: '/locales',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/locales/index')
                }
            ]
        },
        {
            path: '/updateProducts',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/updateProducts/index')
                }
            ]
        },
        {
            path: '/redirect',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '/redirect/:path(.*)',
                    component: () => import('@/pages/redirect/index')
                }
            ]
        },
        {
            path: '/estados',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/estados/index')
                }
            ]
        },
        {
            path: '/login',
            component: Login,
            hidden: true
        },
        {
            path: '/clientes',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/clientes/index')
                }
            ]
        },
        {
            path: '/productos',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/productos/index')
                }
            ]
        },

        {
            path: '/usuarios',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/usuarios/index')
                }
            ]
        },
        {
            path: '/reclamaciones',
            component: Layout,
            hidden: true,
            children: [
                {
                    path: '',
                    component: () => import('@/pages/reclamaciones/index')
                }
            ]
        },
        {
            path: '/xlsx',
            component: Xlsx,
            hidden: true
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'Dashboard',
                    component: lazyComponent('dashboard/index')
                }
            ]
        },
        {
            path: '/canales',
            component: Layout,
            //redirect: 'noRedirect',
            name: 'Canales',
            meta: {
                title: 'Canales',
                icon: 'chart'
            },
            children: [
                {
                    path: 'main',
                    component: () => import('@/pages/canales/index'),
                    name: 'mainCanales',
                    meta: { title: 'Canales', noCache: true }
                },
                {
                    path: 'origen',
                    component: () => import('@/pages/canales/origen'),
                    name: 'mainOrigen',
                    meta: { title: 'Origen', noCache: true }
                }
            ]
        }
    ],
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})
