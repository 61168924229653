import { format, subDays, differenceInDays } from 'date-fns'

export default class ProspectosModel {
    static BaseForm() {
        return {
            descripcion: '',
            idOrigen: null,
            idDist: null,
            idProv: null,
            idDepa: null,
            departamento: null,
            provincia: null,
            idPersona: null,
            idModelo:null,
            idMarca:null,
            marcaOrigen:null,
            modeloOrigen:null,
            idTipoProspecto: null,
            idProducto: null,
            idMetodoEnvio: null,
            usuarioModificacion: 'SYSTEM',
            usuarioCreacion: 'SYSTEM',
            activo: 1,
            idTipoPago: null,
            ubigeo: null,
            idTipoDocumento: 1,
            isEnvioCliente: false,
            nombreCompletos: '',
            numeroDocumento: '',
            nombres: '',
            apellidos: '',
            telefono: '',
            correo: '',
            direccion: '',
            idEstado: null
        }
    }
    static BaseFormItem() {
        return {
            fechaMovimiento: format(new Date(), 'YYYY-MM-DD'),
            horaMovimiento: format(new Date(), 'HH:mm:ss'),
            idEstado: null,
            usuarioModificacion: null,
            descripcion: null,
            idProspecto: null
        }
    }
}
