import request from '@/utils/request'
import DefaultModel from '@/models/estados'

import { sortItems } from '../../utils/genericUtils'
const state = {
    lista: [],
    listaComplaints: [],
    datos: new DefaultModel.BaseForm()
}

const getters = {
    lista: (state) => {
        /* (state.lista.length>0 ? state.lista : []) */
        return sortItems(state.lista, 'orden', 2)
    },
    listaComplaints: (state) => {
        /* (state.lista.length>0 ? state.lista : []) */
        return sortItems(state.listaComplaints, 'orden', 2)
    }
}

const mutations = {
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    SET_LISTA_COMPLAINTS: (state, payload) => {
        state.listaComplaints = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerLista({ commit, state }, payload) {
        console.log('PAYLOAD ', payload)
        if (state.lista.length < 1 || payload.status) {
            console.log('payload ', payload)
            const { data } = await request({
                url: '/api/private/estados',
                method: 'GET',
                params: {}
            })
            console.log('ESTADOS_FROM_SERVER', data)
            commit('SET_LISTA', data.payload)
        } else {
            console.log('ESTADOS_FROM_CACHE')
        }
    },
    async obtenerListaComplaints({ commit, state }, payload) {
        if (state.listaComplaints.length < 1 || payload.status) {
            const { data } = await request({
                url: '/ms/servicios/cm/v2.0/complaints/estados',
                method: 'GET'
            })
            console.log('data_status',data)
            commit('SET_LISTA_COMPLAINTS', data)
        } else {
            console.log('ESTADOS_FROM_CACHE')
        }
    },
    async registrar({ commit }, payload) {
        console.log('post_payload ', payload)
        payload.orden = parseInt(payload.orden)
        const { data } = await request({
            url: '/api/private/estados/',
            method: 'POST',
            data: payload
        })
    },
    async actualizar({ commit, rootState }, payload) {
        const datos = rootState.usuarios.userLogin
        payload.usuarioModificacion = datos.usuario
        payload.orden = parseInt(payload.orden)
        console.log('post_payload ', payload)

        const { data } = await request({
            url: `/api/private/estados/${payload.id}`,
            method: 'PUT',
            data: payload
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
