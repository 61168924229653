import axios from 'axios'
import DefaultModel from '@/models/locales'
import { format } from 'date-fns'

const state = () => ({
    productos: [],
    datos: new DefaultModel.BaseForm()
})

const getters = {
    productos: (state) => {
        return state.productos.length > 0 ? state.productos : []
    }
}

const mutations = {
    SET_PRODUCTOS: (state, payload) => {
        state.productos = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerproductos({ commit }, payload) {
        console.log('HEADERR11_____')
        let arrayProducts = []
        const { data } = await axios({
            url: `/externalProductApiWooc/wc/v3/products/`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Basic Y2tfYjBkMDgzZTFjZTdkNGU4MGRhODUyZDk4NzYwZjA3MWMzNjRjYzY3Mjpjc18zZTkxMDRhYWMzMWMxMWIwOTAwNWYxMTk1YWE2M2VlMDU0ZDM0MDkz'
            }
        })
        for (const iterator of data) {
            const dataVariations = await axios({
                url: `/externalProductApiWooc/wc/v3/products/${iterator.id}/variations`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: 'Basic Y2tfYjBkMDgzZTFjZTdkNGU4MGRhODUyZDk4NzYwZjA3MWMzNjRjYzY3Mjpjc18zZTkxMDRhYWMzMWMxMWIwOTAwNWYxMTk1YWE2M2VlMDU0ZDM0MDkz'
                }
            })

            for (const iterator3 of dataVariations.data) {
                console.log('iterator3', iterator3)
                arrayProducts.push({
                    regular_price: iterator3.regular_price ? iterator3.regular_price : 'No existe stock del producto',
                    sale_price: iterator3.sale_price ? iterator3.sale_price : 'No existe stock del producto',
                    color: iterator3.attributes[0].option,
                    stock_quantity: iterator3.sale_price ? iterator3.stock_quantity : 'No existe stock del producto',
                    id: iterator.id,
                    id_variations: iterator3.id,
                    nombre: iterator.name
                })
            }
        }
        console.log('arrayProducts', arrayProducts)
        commit('SET_PRODUCTOS', arrayProducts)
    },
    async registrar({ commit }, payload) {
        console.log('post_payload ', payload)
        let formData = new FormData()
        formData.append('file', payload)
        console.log('formData', formData)
        const { data } = await axios({
            url: `/ms/servicios/public/1.0/update/products`,
            baseURL: process.env.VUE_APP_URL_INNOUT,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'Basic Y2tfYjBkMDgzZTFjZTdkNGU4MGRhODUyZDk4NzYwZjA3MWMzNjRjYzY3Mjpjc18zZTkxMDRhYWMzMWMxMWIwOTAwNWYxMTk1YWE2M2VlMDU0ZDM0MDkz'
            },
            data: formData
        })
        console.log('data', data)
        return data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
