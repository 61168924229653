import request from '@/utils/request'
import DefaultModel from '@/models/canales'
import { format } from 'date-fns'

const state = {
    lista: [],
    departamentos: [],
    provincias: [],
    distritos: [],
    datos: new DefaultModel.BaseForm()
}

const getters = {
    lista: (_state) => (_state.lista.length > 0 ? _state.lista : []),
    departamentoAssign: (state, getters, rootState, rootGetters) => {        
        const oRoles = rootGetters['usuarios/optionalRole']
        //console.log("oRoles ",oRoles)
        if (oRoles.allLocation) {
            return state.departamentos
        } else {
            const idDepas = oRoles.idDepartamento            
           // console.log("idDepas ",idDepas)
            return state.departamentos.filter((x) => idDepas.some((y) => x.idDepa == y))
        }
    }
}

const mutations = {
    SET_DEPARTAMENTOS: (state, payload) => {
        state.departamentos = payload
    },
    SET_PROVINCIAS: (state, payload) => {
        state.provincias = payload
    },
    SET_DISTRITOS: (state, payload) => {
        state.distritos = payload
    },
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerListaDepartamentos({ state, commit }, payload) {
        if (state.departamentos.length < 1) {
            const { data } = await request({
                url: '/api/private/ubigeo/departamentos',
                method: 'GET'
            })

            commit('SET_DEPARTAMENTOS', data.payload)
        } else {
            console.log('DEPARTAMENTOS_FROM_CACHE')
        }
    },
    async obtenerListaPronvincias({ commit }, payload) {
        const id = payload.id
        const { data } = await request({
            url: `/api/private/ubigeo/departamentos/${id}/provincias`,
            method: 'GET'
        })

        console.log('obtenerLista', data)
        commit('SET_PROVINCIAS', data.payload)
    },
    async obtenerListaDistritos({ commit }, payload) {
        const id = payload.id
        const { data } = await request({
            url: `/api/private/ubigeo/provincias/${id}/distritos`,
            method: 'GET'
        })

        console.log('obtenerLista', data)
        commit('SET_DISTRITOS', data.payload)
    },
    async obtenerDistrito({ commit }, payload) {
        const id = payload.id
        const { data } = await request({
            url: `/api/private/ubigeo/distrito/${id}`,
            method: 'GET'
        })
        console.log('obtenerLista', data)
        commit('SET_DISTRITOS', data.payload)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
