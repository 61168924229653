const state = {
  genericUtils: [],
};

const mutations = {
  UPDATE_CATEGORY(state, payload) {
    state.genericUtils = payload;
  },
};
const actions = {
  async getCategory({ commit, rootState, state }) {
    // console.log(state.genericUtils);
    const { idBussines } = rootState.users.user;

    const query = encodeURIComponent(`{"where":{"and":[{"idBussines": ${idBussines}}]}}`);

    if (state.genericUtils.length < 1) {
      console.log('categories_from_server');

      const response = await axios({
        url: `/intranet/api/tb_cat_produs?filter=${query}`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: process.env.VUE_APP_AUTHORIZATION,
        },
      });

      commit('UPDATE_CATEGORY', response.data);
    } else {
      console.log('categories_from_cache');
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
