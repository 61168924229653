export default class ClientesModel {


  static BaseForm() {
    return {
      activo: 1,
      descripcion: ""
    };
  }
}
