import { format, subDays, differenceInDays } from 'date-fns'

export default class ReclamosModel {
    static BaseForm() {
        return {
            empresa: null,
            fecha: null,
            hojaReclamo: null,
            departamento: null,
            correo: null,
            name: null,
            documento: null,
            producto: null,
            detalle: null,
            tipoReclamo: null
        }
    }
    static BaseFormItem() {
        return {
            fechaMovimiento: format(new Date(), 'YYYY-MM-DD'),
            horaMovimiento: format(new Date(), 'HH:mm:ss'),
            idEstado: null,
            usuarioModificacion: null,
            descripcion: null,
            idComplaints: null,
            result: null,
            area: null
        }
    }
    static BaseFormManual() {
        return {
            notPrintToPdf: true,
            idComplaintsExternal: null,
            isTipoDocumentoCliente: '',
            isNumeroDocumentoCliente: null,
            firstName: '',
            isMaterno: '',
            isPaterno: '',
            isTelefono: '',
            email: '',
            isDepartamento: null,
            isProvincia: null,
            isDistrito: null,
            idDireccion: null,
            isBussines: '',
            isTipoBien: '',
            isAltProductoServicio: null,
            isMonto: null,
            isIdPremises: '',
            isAltPremises: '',
            isTipoReclamacion: null,
            isTipoReclamoQueja: null,
            isOtroTipoReclamo: '',
            isDescripcionReclamo: null,
            isPedido: null,
            typeNotification: 'DOMICILIO'
        }
    }
}
