import request from '@/utils/request'
import DefaultModel from '@/models/origen'
import { format } from 'date-fns'

const state = {
    lista: [],
    datos: new DefaultModel.BaseForm()
}

const getters = {
    lista: (_state) => (_state.lista.length>0 ? _state.lista.sort((a,b)=>(a.idCanal-b.idCanal)) : [])
}

const mutations = {
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerLista({ commit }, payload) {
        const { data } = await request({
            url: '/api/private/origen',
            method: 'GET'
        })

        console.log('obtenerLista', data)
        commit('SET_LISTA', data.payload)
    },
    async registrar({ commit }, payload) {
        console.log('post_payload ', payload)
        const { data } = await request({
            url: '/api/private/origen/',
            method: 'POST',
            data: payload
        })
        console.log('data', data)
    },
    async actualizar({ commit, rootState }, payload) {
        const datos = rootState.usuarios.userLogin
        payload.usuarioModificacion = datos.usuario
        console.log('post_payload ', payload)

        const { data } = await request({
            url: `/api/private/origen/${payload.id}`,
            method: 'PUT',
            data: payload
        })
        console.log('data', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
