import axios from 'axios'
import DefaultModel from '@/models/locales'
import { format } from 'date-fns'

const state = () => ({
    lista: [],
    datos: new DefaultModel.BaseForm()
})

const getters = {
    lista: (state) => {
        return state.lista.length > 0 ? state.lista : []
    }
}

const mutations = {
    SET_LISTA: (state, payload) => {
        state.lista = payload
    },
    RESET_FORM: (state, payload) => {
        state.datos = new DefaultModel.BaseForm()
    },
    UPDATE_DATOS: (state, payload) => {
        state.datos = payload
    }
}

const actions = {
    async obtenerLista({ commit }, payload) {
        const { data } = await axios({
            url: '/ms/servicios/pk/v1.1/cayman/obtener/locales',
            baseURL: process.env.VUE_APP_URL_INNOUT,
            method: 'POST',
            headers: {
                Authorization: process.env.VUE_APP_INNOUT_EXTERNAL
            }
        })

        console.log('obtenerLista', data)
        commit('SET_LISTA', data)
    },
    async registrar({ commit }, payload) {
        console.log('post_payload ', payload)
        let formData = new FormData()
        formData.append('file', payload)

        const { data } = await axios({
            url: '/ms/servicios/pk/v1.1/cayman/upload/locales',
            baseURL: process.env.VUE_APP_URL_INNOUT,
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data', Authorization: process.env.VUE_APP_INNOUT_EXTERNAL },
            data: formData
        })
        console.log('data', data)
        return data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
