export default class ClientesModel {
    static BaseForm() {
        return {
            descripcion: null,
            idTipoMoneda: 1,
            importe: null,
            idReferenciaExterna: null,
            pathReference: null,
            fechaCreacion: null,
            stock: null,
            activo: 1,
            id: null
        }
    }
}
