<template>
    <div class="app-container">
        <div>
            <FilenameOption v-model="filename" />
            <AutoWidthOption v-model="autoWidth" />
            <BookTypeOption v-model="bookType" />
            <el-button :loading="downloadLoading" style="margin: 0 0 20px 20px" type="primary" icon="el-icon-document" @click="handleDownload">
                Export Excel
            </el-button>
            <a href="https://panjiachen.github.io/vue-element-admin-site/feature/component/excel.html" target="_blank" style="margin-left: 15px">
                <el-tag type="info">Documentation</el-tag>
            </a>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading..." border fit highlight-current-row>
            <el-table-column align="center" label="Id" width="95">
                <template slot-scope="scope">
                    {{ scope.$index }}
                </template>
            </el-table-column>
            <el-table-column label="Title">
                <template slot-scope="scope">
                    {{ scope.row.title }}
                </template>
            </el-table-column>
            <el-table-column label="Author" width="110" align="center">
                <template slot-scope="scope">
                    <el-tag>{{ scope.row.author }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Readings" width="115" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pageviews }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="Date" width="220">
                <template slot-scope="scope">
                    <i class="el-icon-time" />
                    <span>{{ scope.row.timestamp | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { parseTime } from '@/utils'
// options components

export default {
    name: 'ExportExcel',
    data() {
        return {
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            lista: [
                {
                    id: '@increment',
                    timestamp: 1123123123,
                    author: '@first',
                    reviewer: '@first',
                    title: '@title(5, 10)',
                    content_short: 'mock data',
                    forecast: '@float(0, 100, 2, 2)',
                    importance: '@integer(1, 3)',
                    'type|1': ['CN', 'US', 'JP', 'EU'],
                    'status|1': ['published', 'draft'],
                    display_time: '@datetime',
                    comment_disabled: true,
                    pageviews: '@integer(300, 5000)',
                    platforms: ['a-platform']
                },
                {
                    id: '@increment',
                    timestamp: 1123123123,
                    author: '@first',
                    reviewer: '@first',
                    title: '@title(5, 10)',
                    content_short: 'mock data',
                    forecast: '@float(0, 100, 2, 2)',
                    importance: '@integer(1, 3)',
                    'type|1': ['CN', 'US', 'JP', 'EU'],
                    'status|1': ['published', 'draft'],
                    display_time: '@datetime',
                    comment_disabled: true,
                    pageviews: '@integer(300, 5000)',

                    platforms: ['a-platform']
                }
            ]
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.listLoading = true
            this.list = this.lista
            this.listLoading = false
        },
        handleDownload() {
            this.downloadLoading = true
            import('@/vendor/Export2Excel').then((excel) => {
                const tHeader = ['Id', 'Title', 'Author', 'Readings', 'Date']
                const filterVal = ['id', 'title', 'author', 'pageviews', 'display_time']
                const list = this.list
                const data = this.formatJson(filterVal, list)
                console.log("ata ",data)
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: this.filename,
                    autoWidth: this.autoWidth,
                    bookType: this.bookType
                })
                this.downloadLoading = false
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) =>
                filterVal.map((j) => {
                    if (j === 'timestamp') {
                        return parseTime(v[j])
                    } else {
                        return v[j]
                    }
                })
            )
        }
    }
}
</script>

<style>
.radio-label {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 30px;
}
</style>
