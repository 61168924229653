<template>
    <v-app id="inspire">
        <v-app-bar app hide-on-scroll class="white" flat>
            <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-uppercase" to="/">
                <strong>GRUPO-CAYMAN</strong>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items> </v-toolbar-items>
            <v-spacer />
            <v-chip class="ml-2" outlined color="green" small> <v-icon left> mdi-android </v-icon> {{ VersionAPP }} </v-chip>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app width="240" dark :mini-variant.sync="mini" class="main">
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-avatar>
                        <v-img src="../assets/logo-zongshen.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title dark> {{ userLogin.usuario }} </v-list-item-title>
                    <v-btn icon @click.stop="mini = !mini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>
                <v-divider></v-divider>
                <v-list nav dense>
                    <template v-if="roles == 'SUPERADMIN'">
                        <v-list-item link to="/usuarios">
                            <v-list-item-icon>
                                <v-icon>mdi-account-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>USUARIOS</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/canales/main">
                            <v-list-item-icon>
                                <v-icon>mdi-grid-large</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>CANALES</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/canales/origen">
                            <v-list-item-icon>
                                <v-icon>mdi-origin</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>ORIGEN</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/productos">
                            <v-list-item-icon>
                                <v-icon>mdi-basket</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>PRODUCTOS</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/clientes">
                            <v-list-item-icon>
                                <v-icon>mdi-account-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>CLIENTES</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/estados">
                            <v-list-item-icon>
                                <v-icon>mdi-family-tree</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>ESTADOS</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/locales">
                            <v-list-item-icon>
                                <v-icon>mdi-map-marker</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>RED DE LOCALES</v-list-item-title>
                        </v-list-item>
                        <!--                         <v-list-item link to="/updateProducts">
                            <v-list-item-icon>
                                <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>ACTUALIZAR PRODUCTOS</v-list-item-title>
                        </v-list-item> -->
                    </template>
                    <!--<template v-else> </template> -->
                    <v-list-item link to="/" v-if="roles != 'LR'">
                        <v-list-item-icon>
                            <v-icon>mdi-chart-line-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>PROSPECTOS</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="roles == 'SUPERADMIN' || optionalRole['download-report'] || roles != 'LR'" link to="/reportes">
                        <v-list-item-icon>
                            <v-icon>mdi-chart-areaspline-variant </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>REPORTES</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/reclamaciones" v-if="roles == 'SUPERADMIN' || roles == 'LR'">
                        <v-list-item-icon>
                            <v-icon>mdi-book-open-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>LIBRO DE RECLAMACIONES </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block @click="cerrarSesion" color="red" v-if="!mini"> Cerrar Sesión </v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-main class="grey lighten-3">
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <v-footer height="48">
            <v-spacer></v-spacer>
            <strong class="caption">
                Powered
                <v-icon color="yellow">mdi-lightbulb-on-outline </v-icon><a href="https://innout.pe">innout.pe</a>
            </strong>
            <v-spacer></v-spacer>
        </v-footer>
    </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { VersionAPP } from '@/utils/auth'

export default {
    data: () => ({
        drawer: true,
        mini: true,
        clipped: true,
        fixed: false,
        miniVariant: false,
        admins: [
            ['Management', 'mdi-account-multiple-outline'],
            ['Settings', 'mdi-cog-outline']
        ],
        cruds: [
            ['Create', 'mdi-plus-outline'],
            ['Read', 'mdi-file-outline'],
            ['Update', 'mdi-update'],
            ['Delete', 'mdi-delete']
        ],
        right: true,
        rightDrawer: false
    }),
    computed: {
        ...mapGetters('usuarios', ['optionalRole']),

        ...mapState('usuarios', ['userLogin', 'roles']),
        VersionAPP() {
            return VersionAPP
        }
    },
    methods: {
        openDrawer() {
            this.drawer = !this.drawer
            this.mini = false
        },
        ...mapActions({
            SIGN_OUT: 'usuarios/resetToken'
        }),
        async cerrarSesion() {
            this.$store.commit('mainUI/OPEN_MODAL', { state: true, overlay: false, color: 'red', width: '400px', text: 'CERRANDO SESIÓN' })
            await this.SIGN_OUT()
        }
    }
}
</script>
<style>
@media only screen and (max-width: 600px) {
    .container {
        padding: 8px !important;
    }
}
</style>
